import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty

function FaqComponent() {
  return (
    <section className="bg-black faq-section">
      <div className="container max-w-4xl px-6 py-5 mx-auto">
        <h1 className="text-3xl md:text-4xl font-semibold text-center text-white">
          <FontAwesomeIcon icon={solid('circle-question')} /> Frequently Asked Questions
        </h1>
        <div className="mt-12 space-y-8">
          <div className="tab2 border-2 rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-one" type="checkbox" name="tabs" />
            <label
              className="text-md md:text-2x1 flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-one"
            >
              What is Steady Stack?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  <b>
                    <i>We are a brand. We are a platform. We are leaders.</i>
                  </b>
                </p>

                <p>
                  <b>
                    <i>The Ultimate Investor Illuminati.</i>
                  </b>
                </p>

                <p>
                  Access & Proximity to the Top Network of Investors, Resources & Exclusive Opportunities comprised of high-level Traders,
                  VC’s, Web3 Natives, Business Owners, Investors & Winners alike. Unparalleled Day 1 Utility. Future of NFTs.
                </p>

                <p>
                  <b>
                    <i>
                      On a mission to Re-Invent NFTs for a more sustainable future with new concepts & much-needed business fundamentals.
                    </i>
                  </b>{' '}
                </p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-two" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-two"
            >
              What is the Steady Stack Titans Collection?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-white">
                <p>
                  Titans are the Genesis drop of the Steady Stack Ecosystem. Titans are the scarcest & most critical NFTs in the entire
                  Steady Stack metaverse.
                </p>

                <p>
                  They grant holders early & exclusive access to the most pivotal elements in the Steady Stack metaverse and give each
                  holder a significant exclusive access, rewards & opportunities.
                </p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-three" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-three"
            >
              Supply? Scarcity within the Investor Illuminati…
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                There will only ever be 2,075 Steady Stack Titans. The Titans Drop will be limited to 2,000 items for sale. 75 Items will be
                reserved for the team, marketing, recruiting, giveaways, and other promotional events.
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-four" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-four"
            >
              When Mint?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>Whitelist Mint: August 2nd, 6pm EST</p>

                <p>Waitlist Mint: August 2nd, 9pm EST</p>

                <p>Is the Whitelist Overallocated?</p>

                <p>
                  No, we have been conservative with our Whitelist and have implemented a Waitlist to avoid overallocation. However, there
                  is a chance Whitelisted wallets may not be able to mint if EVERY Whitelisted wallet mints their Max Mints. We are slightly
                  over 1000 Whitelisted wallets.
                </p>

                <p>Should there be any remaining supply, Waitlist will mint 3 hours after Whitelist mint opens.</p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-eleven" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-eleven"
            >
              Is the Whitelist Overallocated?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  No, we have been conservative with our Whitelist and have implemented a Waitlist to avoid overallocation. However, there
                  is a chance Whitelisted wallets may not be able to mint if EVERY Whitelisted wallet mints their Max Mints. We are slightly
                  over 1000 Whitelisted wallets.{' '}
                </p>

                <p>Should there be any remaining supply, Waitlist will mint 3 hours after Whitelist mint opens.</p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-five" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-five"
            >
              What are some Titans Utilities?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  We are just scratching the surface with Titans Utility. Much of which has been displayed for months in the discord
                  Pre-Mint. Titans utility will consistently be expanded upon leading to the #1 utility in all of NFTs.
                </p>

                <p>
                  <b>A short list of Titan Utilities are listed below:</b>
                </p>

                <ul className="faq-question-li">
                  <li>
                    Lifetime Access to Purchase Proprietary Trading Software Algorithm - this will be offered as a SAAS subscription NFT,
                    also how we are building cashflow into Steady Stack, pushing towards a more sustainable future for NFTs{' '}
                  </li>

                  <li>Full Lenght Trading Course + Access to More Courses in NFTs, Real Estate, Ecom + more</li>

                  <li>
                    Top-Tier Alpha Callers in Crypto/NFTs/Options value $100/mo each AMA’s with 7-9 investors, traders, entrepreneurs, RE,
                    Ecom, etc (priceless){' '}
                  </li>

                  <li>Network & proximity to like-minded community (priceless) </li>

                  <li>
                    Community driven initiatives - from WL raffles with top projects to AMA outreach, Steady Stack News, Podcasts + more{' '}
                  </li>

                  <li>Potential Meetups & Events (priceless)</li>

                  <li>
                    Exclusive investment opps — from potential crypto mining accesses, to Passive Cashflow services such as YouTube,
                    Potential IPO/IDO/IGO allocations, Exclusive Real Estate Access, Investments + a lot more (these are still being worked
                    out, not set in stone yet but a part of the vision. We've had countless reach out for partnerships for these. We are
                    vetting and seeing who will be a fit)
                  </li>
                </ul>

                <p>
                  Ultimately, this is an Investor Illuminati. Access & Proximity to the best. Resources, network & Ultimate Investor
                  Mastermind. We are working hard to continuously add value to our core demographic. We have something very unique here.
                </p>

                <p>We are just getting started.</p>

                <p>The best part: There’s A LOT more to come…</p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-six" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-six"
            >
              Is there a future roadmap & white paper?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  As with all things Steady Stack, we like to under promise and over deliver. To get a sneak peak into our future, carefully
                  review the discord & watch our Interview on Crypto Banter here. (
                  <a href="https://www.youtube.com/watch?v=qoYcArXWQ08" target="_blank">
                    https://www.youtube.com/watch?v=qoYcArXWQ08
                  </a>
                  )
                </p>

                <p>
                  Keep an eye on{' '}
                  <a href="https://medium.com/@steadystackNFT" target="_blank">
                    Medium.com/@steadystackNFT
                  </a>{' '}
                  for more formal updates & announcements.
                </p>

                <p>We will have more formal updates, blogs roadmaps & outlines post-mint.</p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-seven" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-seven"
            >
              Are there Rewards for Staking?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                There will be staking rewards announced post-mint. These will be rewards tied into the Steady Stack ecosystem. We want to
                reward community members who are 💎🤲. We’ll be announcing specific rewards in the near future.
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-eight" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-eight"
            >
              Who is the Dev Team behind the Smart Contract?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                We have a star-studded Smart Contract Dev Team led by the Developer of NeoTokyo, Thrasher & his Launchpad Byt.
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-nine" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-nine"
            >
              What does “Re-Invent the NFT Space” Mean? How?
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  Since Day 1 Steady Stack has Re-Written Common Metas to lead to a brighter future for NFTs. Taking an approach of the 1%
                  vs following the 99%.
                </p>

                <p>
                  From starting the discord with Countless Pre-Mint Utility to Handpicking a vast majority of our Whitelist to ensure the
                  highest quality of community within our walls. Everything is done in an effort to lead to a more sustainable &
                  quality-based future for NFTs.
                </p>

                <p>
                  This is why we focus so much on Utility & sustainable business practices. These are the concepts that will take NFTs to
                  the next level & build enough foundation for NFTs to sustain long enough to display the benefits of transitioning to Web3.
                </p>

                <p>
                  We will continue to spearhead new concepts to achieve our vision of creating more sustainability in NFTs post-mint, these
                  core concepts include:
                </p>
              </div>
            </div>
          </div>

          <div className="tab2 border-2  rounded-lg border-gray-700">
            <input className="absolute opacity-0" id="tab-single-ten" type="checkbox" name="tabs" />
            <label
              className="flex items-center justify-between w-full p-8 pt-4 font-semibold text-gray-700 dark:text-white cursor-pointer"
              htmlFor="tab-single-ten"
            >
              Is Steady Stack an Alpha group?{' '}
            </label>

            <div className="tab-content leading-normal  overflow-hidden">
              <hr className="border-gray-200 dark:border-gray-700" />

              <div className="p-8 pt-4 text-md text-gray-500 dark:text-gray-300">
                <p>
                  We are 100x an "alpha group". Reason being is that NO alpha group comes with the underlying DAY 1 UTILITY that Steady
                  Stack offers which puts Titans in a league of their own.
                </p>

                <p>
                  From access to our Proprietary Trading SaaS Technology to guide better entries & exits among all the priceless value
                  inside from Masterminds & AMA's with 8/9 figure leaders across Investing Niches in Trading, Investing, Business, Real
                  Estate + more.{' '}
                </p>

                <p>
                  Alpha Groups can quickly become saturated because there's no barrier protecting the group. If an Alpha group gives alpha &
                  100 members also have alpha groups, the alpha gets passed around to the point it is NO longer alpha.{' '}
                  <b>
                    With our unique value propositions from Access to our Trading SaaS & other Exclusive Opportunities to our AMA's to our
                    community driven initiatives under our leadership, there is no other ecosystem in the NFT space like the
                    Industry-Leading Steady Stack Titans.
                  </b>{' '}
                </p>

                <p>
                  <b>One-of-a-kind Investor Mastermind Illuminati.</b>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaqComponent
