import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useAccount, useContractRead, useContractWrite, usePrepareContractWrite, useProvider, useWaitForTransaction } from 'wagmi'
import contractInterface from '../../mintingABI'
import { parse } from 'papaparse'
import { useForm } from 'react-hook-form'
import { ethers } from 'ethers'
import { IsJsonString } from '../../utils'
import FlipCard, { BackCard, FrontCard } from './flip-card.component'
import { Link } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro'
import CarsouselComponent from '../carousel/carousel.component'

const MINT_PRICE_ETH = '75'
const CONTRACT_ADDRESS = '0x050A5A8722395e518ab7B1548F1184DB6024a9fd'

export interface WhitelistData {
  wallet: string
  whitelistQuantity: string
  spotInWhitelist: string
  proof: string
}

export interface WalletData {
  connected: boolean
  walletAddress: string
  chainId: number
}

const contractConfig = {
  addressOrName: CONTRACT_ADDRESS,
  contractInterface: contractInterface,
}

const RainbowKitMint = memo(() => {
  const [totalMintSupply, setTotalMintSupply] = React.useState(0)
  const [maxSupply, setMaxSupply] = React.useState(0)
  const [totalMinted, setTotalMinted] = React.useState(0)
  const { isConnected, address } = useAccount()

  const [walletData, setWalletData] = useState<WalletData>()
  const [numberToMint, setNumberToMint] = useState<number>(1)

  const [status, setStatus] = useState<string>('')
  const [statusType, setStatusType] = useState<string>('')

  /* Get maxSupply value from contract (max tokens that can be minted) */
  const { data: maxSupplyData } = useContractRead({
    ...contractConfig,
    functionName: 'MAX_SUPPLY',
    watch: true,
  })

  /* Set maxSupply state value */
  useEffect(() => {
    if (maxSupplyData) {
      setMaxSupply(maxSupplyData as any)
    }
  }, [maxSupplyData])

  const { data: totalMintsFromWallet } = useContractRead({
    ...contractConfig,
    functionName: 'balanceOf',
    watch: true,
    args: [walletData?.walletAddress],
  })
  /* Set total NFTs Minted by this wallet */
  useEffect(() => {
    if (totalMintsFromWallet) {
      setTotalMinted(totalMintsFromWallet as any)
    }
  }, [totalMintsFromWallet])

  /* Set the users wallet data state object */
  useEffect(() => {
    setWalletData({
      connected: true,
      walletAddress: `${address}`,
      chainId: 40,
    })
  }, [address])

  /* Get totalSupply value (total nft tokens minted so far) */
  const { data: totalSupplyData } = useContractRead({
    ...contractConfig,
    functionName: 'totalSupply',
    watch: true,
  })

  /* Set totalSupply of NFTs minted so far for this contract */
  useEffect(() => {
    if (totalSupplyData) {
      setTotalMintSupply(totalSupplyData as any)
    }
  }, [totalSupplyData])

  /* Set the users wallet data state object */
  useEffect(() => {
    setWalletData({
      connected: true,
      walletAddress: `${address}`,
      chainId: 40,
    })
  }, [address])

  /* Setup mint function ready to be fired off when buttton is pushed */
  const { config: contractWriteConfig, error: prepareContractError } = usePrepareContractWrite({
    abi: contractInterface as any,
    functionName: 'mint',
    args: [numberToMint],
    overrides: {
      value: ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint),
    },
  })

  /* Setup mint function ready to be fired off when buttton is pushed */
  const {
    data: mintData,
    write: mint,
    isLoading: isMintLoading,
    isSuccess: isMintStarted,
    error: mintError,
  } = useContractWrite(contractWriteConfig as any)

  /* Setup mint transaction response data */
  const {
    data: txData,
    isSuccess: txSuccess,
    error: txError,
  } = useWaitForTransaction({
    hash: mintData?.hash,
  })

  /* onClick event for form button, initiates transaction approval window */
  const whitelistMint = async () => {
    try {
      if (!address) {
        enqueueSnackbar('Error, you must connect to a wallet first.', { variant: 'error' })
        return
      }
      setStatus(`Minting ${numberToMint} NFT(s)...`)
      const mintVal = mint?.()
    } catch (error: any) {
      enqueueSnackbar(error?.reason, { variant: 'error' })
    }
  }

  /* Handle any errors that we might receive from the contract when preparing the write function */
  useEffect(() => {
    if (prepareContractError?.message) {
      const msg = prepareContractError?.message.substring(prepareContractError?.message.indexOf('error={"code"') + 6).split(', code=')[0]
      if (IsJsonString(msg)) {
        const transactionResponse = JSON.parse(msg)
        const err = transactionResponse?.message.replace('execution reverted:', 'Error:')
        setStatus(err)
        enqueueSnackbar(err, { variant: 'error' })
        setStatusType('error')
      }
    }
  }, [prepareContractError])

  /* Update status message when txSuccess boolean changes */
  useEffect(() => {
    if (txSuccess) {
      setStatus('Mint NFT')
      enqueueSnackbar('Your NFT(s) have been successfully minted.', { variant: 'success' })
      setStatusType('success')
    }
  }, [txSuccess])

  /* Show snackbar error & update statusMessage & type states if/when txError changes. */
  useEffect(() => {
    if (txError?.message) {
      setStatus(txError.message)
      setStatusType('error')
      enqueueSnackbar(txError.message, { variant: 'error' })
    }
  }, [txError])

  /* Show snackbar error & update statusMessage & type states if/when mintError changes. */
  useEffect(() => {
    if (mintError?.message) {
      setStatus(mintError.message)
      setStatusType('error')
      enqueueSnackbar(mintError.message, { variant: 'error' })
    }
  }, [mintError])

  const minimumMintsAllowed = 1
  const maximumMintsAllowed = 5

  const { handleSubmit } = useForm()

  return (
    <div className="page">
      <form onSubmit={handleSubmit(whitelistMint)}>
        <div className="">
          <p className="text-2xl text-white font-bold mb-2">
            <FontAwesomeIcon icon={brands('wolf-pack-battalion')} /> Acwulf NFT Mint{' '}
            <small>
              ({totalMintSupply}/{maxSupply} Minted)
            </small>
          </p>

          <div className="flex-row md:flex">
            <div className="flex-col mr-4">
              <FlipCard>
                <FrontCard isCardFlipped={txSuccess}>
                  <img src="https://byt.nyc3.cdn.digitaloceanspaces.com/media/acwulf/ezgif.com-gif-maker%282%29.gif" className="rounded-lg" alt="Acwulf Video / Gif" />
                </FrontCard>
                <BackCard isCardFlipped={txSuccess}>
                  <div style={{ padding: 24, textAlign: 'center' }}>
                      <img src="https://byt.nyc3.cdn.digitaloceanspaces.com/media/acwulf/ezgif.com-gif-maker%282%29.gif" className="rounded-lg" alt="Acwulf Video / Gif" />
                    <p style={{ marginBottom: 6 }}>
                      View on <a href={`https://testnet.teloscan.io/tx/${mintData?.hash}`}>Teloscan</a>
                    </p>
                    <p>
                      View on <a href={`https://byt.io/telos/collection/acwulf`}>Byt.io</a>
                    </p>
                  </div>
                </BackCard>
              </FlipCard>
            </div>

            <div className="flex-col w-1/2">
              <label htmlFor="name" className="mb-3 block text-base font-medium text-white">
                NFTs to Mint: <small>(Max of 10 NFTs Each Tx)</small>
              </label>

              <div className="">
                <div className="custom-number-input h-10 w-32 mb-6 mt-6">
                    <label htmlFor="custom-input-number" className="w-full text-white text-sm font-semibold">NFTs to Mint:</label>
                    <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
                        <button type="button" data-action="decrement" className="text-gray-300 bg-gray-400 hover:bg-gray-600 hover:text-white h-full w-20 rounded-l cursor-pointer outline-none"  onClick={() => {setNumberToMint(numberToMint-1 < 0 ? 0 : numberToMint-1)}}>
                          <span className="m-auto text-2xl font-thin">−</span>
                        </button>
                        <input type="input"
                                value={numberToMint}
                                min="1"
                                onChange={(event) => setNumberToMint(Number(event?.target.value))}
                                className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none" name="custom-input-number"></input>
                      <button type="button" data-action="increment" className="text-gray-300 bg-gray-400 hover:bg-gray-600 hover:text-white h-full w-20 rounded-r cursor-pointer" onClick={() => {setNumberToMint(numberToMint+1)}}>
                        <span className="m-auto text-2xl font-thin">+</span>
                      </button>
                      
                    </div>
                </div>
                <div className="w-full h-10 align-middle pt-8 pl-6">
                  {numberToMint} x {ethers.utils.formatEther(ethers.utils.parseEther(MINT_PRICE_ETH))}TLOS = <>{ethers.utils.formatEther(ethers.utils.parseEther(MINT_PRICE_ETH).mul(numberToMint))}ETH</>
                </div>
              </div>

              <div className="text-white">
                {totalMinted > 0 && <p style={{ margin: '12px 0 5px', color: 'green' }}>You have minted {totalMinted} NFTs.</p>}

                {status && <p style={{ color: statusType == 'error' ? 'red' : 'green' }}>{status}</p>}

                {mintError && <p style={{ marginTop: 24, color: '#FF6257' }}>Error: {mintError.message}</p>}
                {txError && <p style={{ marginTop: 24, color: '#FF6257' }}>Error: {txError.message}</p>}

                <button
                  style={{ marginTop: 5 }}
                  className="hover:shadow-form rounded-md bg-[#6A64F1] py-3 px-8 text-base font-semibold text-white outline-none"
                  data-mint-loading={isMintLoading}
                  data-mint-started={isMintStarted}
                  type="submit"
                >
                  {status && statusType != 'error' && status}
                  {isMintLoading && 'Waiting for approval'}
                  {!isMintLoading && !isMintStarted && 'Mint NFT!'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
})

export default RainbowKitMint
