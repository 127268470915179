function importAll(r) {
  return r.keys().map(r)
}

const images = importAll(require.context('../public/art', false, /\.(png)$/))

export const listofImages = images.map((item) => {
  return item
})

export const IsJsonString = (str) => {
  try {
    var json = JSON.parse(str)
    return typeof json === 'object'
  } catch (e) {
    return false
  }
}
