import './App.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid, regular, brands } from '@fortawesome/fontawesome-svg-core/import.macro' // <-- import sty
import React, { useState, useEffect } from 'react'
import CarsouselComponent from './components/carousel/carousel.component'
import { ConnectButton } from '@rainbow-me/rainbowkit'
import FaqComponent from './components/faq/faq.component'
import RainbowKitMint from './components/rainbowkit-mint/rainbowkit-mint.component'
import { BrowserRouter as Router, Routes, Route, Link, useNavigate } from 'react-router-dom'
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  )
}

const Home = () => {
  const navigate = useNavigate()

  const onClickMasterLink = () => {
    const htmlMintValEl = document.getElementById('totalMinted')?.innerHTML ?? '0'
    const totalMinted = parseInt(htmlMintValEl)
    const plural = totalMinted > 1 ? 's' : ''
    navigate('/masterclass')
  }

  return (
    <>
      <nav id="header" className="top-0 ">
        <div className="container max-w-7xl px-3 md:px-6 py-0 mx-auto">
          <div className="relative flex-row md:flex items-center md:justify-start	text-center justify-between">
            <div className="flex flex-col md:flex items-center justify-center md:justify-start	sm:items-stretch sm:justify-start">
              <div className="justify-center md:justify-start	 sm:ml-4 flex-shrink-0 flex items-center text-center">
                <Link to="/">
                  <img className="main_logo relative top-4 relative bottom-1 h-24 rounded-lg" src="/art/10-removebg-preview.png" alt="Acwulf NFT Logo" />
                </Link>
              </div>
            </div>

            <div className="flex-col md:flex block md:inline pull-right pr-2 sm:static sm:inset-auto sm:pr-0 mt-4">
              <div className="flex space-x-0 justify-center items-center">
                <div className="container mx-auto">
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-1 mt-6 md:mt-0">
                    <div className="flex justify-center md:justify-end">
                      <a
                        href="https://discordapp.com/users/1011376091384008798/"
                        target="_blank"
                        className="text-gray-300 bg-emerald-700 hover:bg-emerald-900 hover:text-white px-4 py-4 rounded-md text-sm" rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={brands('telegram')} /> Join Acwulf Discord
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://twitter.com/Sunitagunjal5"
                        target="_blank"
                        className="text-gray-300 bg-blue-500 hover:bg-blue-700 hover:text-white px-4 py-4 rounded-md text-sm" rel="noreferrer"
                      >
                        <FontAwesomeIcon icon={brands('twitter')} /> Follow Acwulf Twitter
                      </a>
                    </div>
                    <div className="flex justify-center">
                      <a
                        href="https://byt.io/telos/collection/acwulf"
                        target="_blank"
                        className="text-gray-300 px-4 py-4 rounded-md text-sm bg-violet-700 hover:bg-violet-900 hover:text-white" rel="noreferrer"
                      >
                        <img
                          src="https://byt.io/favicon.ico"
                          width="16"
                          height="16"
                          alt="Byt Logo Icon"
                          style={{ display: 'inline', borderRadius: '3px', position: 'relative', top: '-2px' }}
                        />{' '}
                        View Acwulf on Byt
                      </a>
                    </div>
                    <div className="flex justify-center md:justify-self-end md:place-self-end static md:absolute">
                      <ConnectButton.Custom>
                        {({ account, chain, openAccountModal, openChainModal, openConnectModal, mounted }) => {
                          return (
                            <div
                              {...(!mounted && {
                                'aria-hidden': true,
                                style: {
                                  opacity: 0,
                                  pointerEvents: 'none',
                                  userSelect: 'none',
                                },
                              })}
                              className="text-gray-300 bg-purple-700  hover:bg-gray-500 hover:text-white px-4 py-4 rounded-md text-sm ml-2"
                            >
                              {(() => {
                                if (!mounted || !account || !chain) {
                                  return (
                                    <button onClick={openConnectModal} type="button">
                                      <FontAwesomeIcon icon={solid('wallet')} /> Connect Wallet
                                    </button>
                                  )
                                }

                                if (chain.unsupported) {
                                  return (
                                    <button onClick={openChainModal} type="button">
                                      Wrong network
                                    </button>
                                  )
                                }

                                return (
                                  <div style={{ display: 'inline' }}>
                                    <button onClick={openChainModal} type="button">
                                      {chain.hasIcon && (
                                        <div
                                          style={{
                                            background: chain.iconBackground,
                                            width: 12,
                                            height: 12,
                                            borderRadius: 999,
                                            overflow: 'hidden',
                                            marginRight: 4,
                                          }}
                                        >
                                          {chain.iconUrl && (
                                            <img alt={chain.name ?? 'Chain icon'} src={chain.iconUrl} style={{ width: 12, height: 12 }} />
                                          )}
                                        </div>
                                      )}
                                    </button>

                                    <button onClick={openAccountModal} type="button">
                                      {account.displayName}
                                      {account.displayBalance ? ` (${account.displayBalance})` : ''}
                                    </button>
                                  </div>
                                )
                              })()}
                            </div>
                          )
                        }}
                      </ConnectButton.Custom>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <div className="bg-black mt-60 md:mt-24">
        <div className="container max-w-7xl px-3 md:px-6 py-5 mx-auto">
          <h1 className="main-header text-center	 font-semibold text-white mb-2">
            <img src="/telos_icon.png" height="32" width="32" className="inline" alt="Telos Logo" /> <span
                className=" mt-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-emerald-400 to-green-500 md:inline-block"
              >  Acwulf Punkk <span className="bg-clip-text text-transparent bg-gradient-to-r from-teal-500 via-cyon-400 to-purple-300"> A premium collection built on Telos</span> </span>

          </h1>
          <h1 className="hidden text-center sub-header	 text-white mb-2">A Premium Anime Collection Built on Telos.</h1>

          <div className="flex-row md:flex mb-0 md:mb-4 mt-6">
            <div className="flex-col md:flex w-full md:w-1/2 mr-2">
              <img src="/Banner.jpg" className="hidden rounded-lg" style={{ maxHeight: '356px' }} alt="Acwulf Banner" />
                          <video
                            className="rounded-xl mt-2"
                            width="100%"
                            height="100%"
                            poster="/video.gif"
                            autoPlay={true}
                            controls
                            preload="auto"
                            loop
                          >
                            <source src="/video.mp4" type="video/mp4" />
                          </video>

            </div>

            <div className="flex-col md:flex w-full md:w-1/2">
              <div className="container mx-auto bg-black border border-white rounded-xl shadow border p-4">
                <RainbowKitMint />
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="bg-black	hidden">
        <div className="container max-w-7xl px-6 py-4 mx-auto">
          <div className="flex-row md:flex mb-4">
            <div className="flex-col md:flex w-full md:w-1/2 mr-4">
              <section className="overflow-hidden text-gray-700">
                <div className="container mx-auto ">
                  <div className="flex flex-wrap -m-1 md:-m-2">
                    <div className="flex flex-wrap w-1/2">
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art/C60E5355-46F2-40C4-896A-85CFB6FC9EE8.gif"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art/C779B1B9-22F7-449D-9F47-18E288BFBC8F.gif"
                        />
                      </div>
                      <div className="w-full p-1 md:p-2">
                        <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src="/art/KevinGIF.gif" />
                      </div>
                    </div>
                    <div className="flex flex-wrap w-1/2">
                      <div className="w-full p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art/CigarIronmanGIF.gif"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img
                          alt="gallery"
                          className="block object-cover object-center w-full h-full rounded-lg"
                          src="/art/ThanosSnapGoldSkull.gif"
                        />
                      </div>
                      <div className="w-1/2 p-1 md:p-2">
                        <img alt="gallery" className="block object-cover object-center w-full h-full rounded-lg" src="/art/RamoAKGIF.gif" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>

      <div className="hidden">
        <FaqComponent />
      </div>
    </>
  )
}

const NotFound = () => {
  return (
    <>
      <div>404 page not found.</div>
    </>
  )
}

export default App
