const BlockchainLogo = () => {
  return (
    <svg width="30" height="30" fill="none" version="1.1" viewBox="0 0 325 323" xmlns="http://www.w3.org/2000/svg">
      <circle cx="163.7" cy="161.5" r="163.21" fill="url(#paint0_linear)" strokeWidth="2.8634" />
      <g transform="matrix(2.8634 0 0 2.8634 .48744 -1.7137)" filter="url(#filter0_i)">
        <circle cx="57" cy="57" r="57" fill="url(#paint1_linear)" />
      </g>
      <circle cx="163.7" cy="161.5" r="161.51" stroke="url(#paint2_linear)" strokeOpacity=".12" strokeWidth="3.4003" />
      <g transform="matrix(2.8634 0 0 2.8634 .48744 -1.7137)" filter="url(#filter1_di)">
        <path
          d="m78.19 29.534c0.2418-0.629-0.1949-1.3122-0.8672-1.3568l-11.337-0.7519c-0.3505-0.0232-0.6874 0.1391-0.8877 0.4276l-3.0039 4.3273c-0.188 0.2709-0.4976 0.4317-0.8273 0.4298l-19.957-0.1178c-0.9792-0.0058-1.9264 0.3478-2.6623 0.9937l-5.5372 4.8601c-1.232 1.0813-1.6795 2.805-1.1292 4.3491l2.6375 7.4c0.0135 0.0382 0.0071 0.0806-0.0173 0.1129-1.4819 1.9699-2.3434 4.3366-2.4748 6.7982l-0.0447 0.8385c-0.2791 5.2305 0.3368 10.469 1.8218 15.493 1.2237 4.1394 2.6261 8.8833 3.4857 11.791 0.4535 1.5341 2.0204 2.4392 3.5759 2.0656 3.3491-0.8043 9.1011-2.1862 13.631-3.2769 3.9835-0.9592 7.7711-2.5945 11.205-4.8294l2.1489-1.3984c2.0661-1.3445 3.6849-3.274 4.65-5.5424 0.0158-0.0372 0.0493-0.064 0.0891-0.0713l7.7274-1.416c1.6124-0.2954 2.8813-1.5448 3.2018-3.1524l1.4404-7.2254c0.1915-0.9603 0.024-1.9574-0.4706-2.8025l-9.3748-16.019c-0.6142-1.0494-0.7177-2.3213-0.2812-3.4562z"
          clipRule="evenodd"
          fillRule="evenodd"
          stroke="#fff"
          strokeWidth="5.5"
        />
      </g>
      <defs>
        <filter id="filter0_i" x="-9.5" y="0" width="123.5" height="114" colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dx="-14.25" />
          <feGaussianBlur stdDeviation="4.75" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0.682353 0 0 0 0 0.45098 0 0 0 0 0.980392 0 0 0 0.56 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
        </filter>
        <filter
          id="filter1_di"
          x="28.038"
          y="24.192"
          width="60.81"
          height="67.277"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="0.480491" />
          <feGaussianBlur stdDeviation="0.480491" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
          <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
          <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
          <feOffset dy="0.480491" />
          <feGaussianBlur stdDeviation="0.480491" />
          <feComposite in2="hardAlpha" k2="-1" k3="1" operator="arithmetic" />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0" />
          <feBlend in2="shape" result="effect2_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1="57"
          x2="57"
          y2="114"
          gradientTransform="matrix(2.8634 0 0 2.8634 .48744 -1.7137)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#130C3F" offset="0" />
          <stop stopColor="#15197B" offset="1" />
        </linearGradient>
        <linearGradient id="paint1_linear" x2="114" y1="57" y2="57" gradientUnits="userSpaceOnUse">
          <stop stopColor="#AE73FA" offset="0" />
          <stop stopColor="#AE73FA" stopOpacity="0" offset="1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="114"
          x2="-1.6656e-6"
          y1="57"
          y2="57"
          gradientTransform="matrix(2.8634 0 0 2.8634 .48744 -1.7137)"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AE73FA" offset="0" />
          <stop stopColor="#AE73FA" stopOpacity="0" offset="1" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default BlockchainLogo
